import React from "react"

import {
  Layout,
  Landing,
  GreenBar,
  Banner,
  SectionVehicle,
  Reviews,
  Trips,
  useHomePageQuery,
  useImageQuery,
  renderSeo,
} from "@nt-websites/navigate-theme"

const OurAccommodation = ({ data }) => {
  // extracting our custom hook

  const imageQuery = useImageQuery()
  const homeQuery = useHomePageQuery()

  return (
    <Layout>
      {renderSeo(data.contentfulSeoPageMeta)}
      <div className="hotfix--narrow-banner hotfix--narrow-banner--yachts">
        <Landing
          imageData={
            imageQuery.grandWildAccommodationBanner.childImageSharp.fluid
          }
          titleFirst="Our Accommodation"
          buttonSecondURL="#popup"
          buttonStyles={["white", "med-blue"]}
          optMargin="u-margin-top-percent-10"
          variation="dest"
          mobileBanner={true}
        />
      </div>
      <GreenBar />
      <SectionVehicle
        introText={
          "Our experiences aren't just about the luxury vehicle, we also stay in boutique accommodation to ensure you get the most out of your time in New Zealand."
        }
        introTitle={
          "Enjoy relaxing evenings with exceptional premium accommodation."
        }
        imageOne={imageQuery.grandWildAccommodation1.childImageSharp.fluid}
        imageTwo={imageQuery.grandWildAccommodation2.childImageSharp.fluid}
        imageThree={imageQuery.grandWildAccommodation3.childImageSharp.fluid}
        imageFour={imageQuery.grandWildAccommodation4.childImageSharp.fluid}
        title="Stay energised throughout the day after a rejuvenating sleep at your premium accommodation."
        paragraph="As you journey with us through New Zealand, we want to ensure that your time with us is as relaxing and memorable as possible. After an incredible day of exploring, you can rest assured that you will be returning to accommodation where the beds will be comfortable, the rooms warm, and the experience exceptional."
        paragraphSecond="From lakeside hotels to rainforest retreats, our trips offer exceptional premium accommodation in the most beautiful parts of the country. Whether you want to spend your evenings enjoying a drink in an infinity pool or star gazing between mountains in a Dark Sky Reserve, we've carefully hand-picked our favourite accommodation for you to spend your evenings in."
      />
      <Banner {...data.contentfulSeoPageMeta.bottomBanner} />
      <Reviews />
      <Trips data={homeQuery[0].node.popularTours} headerText="Popular Tours" />
    </Layout>
  )
}

export default OurAccommodation

/**
 * We should use seo identifier variables from const PAGE_SEO_IDENTIFIER on this query instead plain strings. . But to do so, we need to pass
 * this data as a context. See LekoArts answer in https://github.com/gatsbyjs/gatsby/issues/10023.
 */
export const query = graphql`
  query {
    contentfulSeoPageMeta(
      referencedPageIdentifier: { eq: "our-accommodation" }
    ) {
      ...PageMeta
    }
  }
`
